import React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import classnames from 'classnames';

import './UpdateGrid.scss';

const UpdateGrid = ({ updateNodes, backgroundColor = 'grey' }) => {
  return (
    <div className="UpdateGrid wrapper">
      <ul className="container grid">
        {updateNodes?.nodes?.map((update) => {
          const { title, date, updateTags, databaseId, uri } = update;
          const tags = updateTags?.nodes;
          return (
            <li className={classnames('item', backgroundColor)} key={databaseId}>
              <Link to={uri} className="presstagcard">
                <div className="tagicon">
                  <GatsbyImage fixed={tags?.[0]?.image?.node?.localFile.childImageSharp.fixed} />
                </div>
                <div className="blogarchive">
                  <div className="tagterm">{tags?.map((tag) => tag?.name)}</div>
                  <div className="description">{title}</div>
                </div>
                <div className="postdate">{date}</div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default UpdateGrid;
