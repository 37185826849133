import React, { memo, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Section from '../../components/Section/Section';
import ViewAll from '../../components/ViewAll/ViewAll';

import './UpdatesPreview.scss';

import { useSectionTimeSpent } from '../../util';
import UpdateGrid from '../UpdateGrid/UpdateGrid';

function UpdatesPreview({ data: { id, name, title } }) {
  const containerRef = useRef(null);

  useSectionTimeSpent(name, containerRef);

  const { allWpUpdate } = useStaticQuery(graphql`
    {
      allWpUpdate(limit: 9, sort: { order: DESC, fields: dateGmt }) {
        nodes {
          ...UpdatePreviewContent
        }
      }
    }
  `);

  return (
    <Section id={id}>
      <div className="UpdatesPreview mediapage">
        <h2 className="title">{title}</h2>
        <Section id="blogItem" class="container">
          <UpdateGrid updateNodes={allWpUpdate} />
          <ViewAll link="/updates/" alignRight />
        </Section>
      </div>
    </Section>
  );
}

export default memo(UpdatesPreview);

export const fragments = graphql`
  fragment UpdatesPreview on WpPage_Flexiblelayout_FlexibleChildren_UpdatesPreview {
    id
    jumpToLinkTitle
    name
    title
  }

  fragment UpdatesPreview_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_UpdatesPreview {
    id
    jumpToLinkTitle
    name
    title
  }
`;
